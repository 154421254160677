<template>
  <div class="index">
    <div class="header">
      <h2 style="color:var( --td-text-color-primary)">展馆管理</h2>
      <t-button theme="primary" @click="add_toast=true,venue_toast_title='添加展馆',this.initForm()">
        <template #icon>
          <t-icon name="add"></t-icon>
        </template>
        添加
      </t-button>
    </div>
    <div class="table">
      <t-table :columns="columns" :data="data" :fixed-rows="[0,0]" :loading="table_loading" headerAffixedTop
               row-key="id"
               style="height: calc(100% - 90px);border-bottom: 1px solid var(--td-border-level-2-color);overflow-y: auto"
               table-layout="fixed">
        <template #work_time="{ col, row }">
          {{ time(row.work_time) }}
        </template>
        <template #opertion="{row}">
          <t-popconfirm content="确认删除该展馆？" theme="danger" @confirm="del_venue(row)">
            <t-button theme="danger" variant="text"> 删除</t-button>
          </t-popconfirm>
          <t-button theme="success" variant="text" @click="edit(row)"> 编辑</t-button>
        </template>
      </t-table>
      <div class="pages">
        <t-pagination
            v-model="pages"
            :pageSize="limit"
            :total="total"
            show-jumper
            style="width: 100%;"
            @currentChange="pageChange"
            @page-size-change="pageSizeChange"
        />
      </div>
    </div>
    <t-dialog :closeOnOverlayClick="false" :header="venue_toast_title" :visible="add_toast" placement="center"
              width="700px" @close="add_toast=false" @confirm="up">
      <template #body>
        <t-form ref="form" :data="formData" :rules="rules">
          <t-form-item label="展馆名" name="name">
            <t-input v-model="formData.name" placeholder="请输入内容"/>
          </t-form-item>
          <t-form-item label="展馆类型" name="type">
            <t-select v-model="formData.type" :options="type_list" placeholder="请选择类型"/>
          </t-form-item>
          <t-form-item label="营业时间" name="work_time">
            <t-time-range-picker v-model="choice_time" class="demos" clearable format="HH:mm" @change="timeChange"/>
          </t-form-item>
          <t-form-item label="时间备注" name="time_tag">
            <t-input v-model="formData.time_tag" placeholder="请输入内容"/>
          </t-form-item>
          <t-form-item label="展馆地址" name="address_str">
            <t-input v-model="formData.address_str" placeholder="请输入内容"/>
          </t-form-item>
          <t-form-item label="展馆坐标" name="address_poin">
            <t-input :value='show_poin' placeholder="请输入内容" readonly @click="this.initMap"/>
          </t-form-item>
          <t-form-item label="展馆内容" name="con">
            <t-textarea v-model="formData.con" autosize placeholder="请输入内容"/>
          </t-form-item>
          <t-form-item help="请勿上传同一张图片" label="展馆图片" name="img">
            <div
                style="width:100%;display: flex;justify-content: flex-start;align-content: flex-start;flex-direction: column">
              <div style="display:flex;margin-bottom: 20px">
                <el-upload
                    :file-list="file1"
                    :http-request="up_img"
                    :on-preview="prev_img_video"
                    :on-remove="del_img"
                    action="https://gqt.yltest.top/file"
                    list-type="picture-card">
                  <icon name="add" size="50"></icon>
                </el-upload>
                <t-dialog :footer="false" :onClose="()=>{prev_toast=false}" :visible="prev_toast" :zIndex="2501"
                          attach="body" destroyOnClose width="800px">
                  <div slot="body" class="prev">
                    <img v-if="/\.(mp4|avi|rm|rmvb|wmv|mkv|mov|flv)$/.test(prev_item)===false" :src="prev_item"/>
                    <video v-else :src="prev_item" controls></video>
                  </div>
                </t-dialog>
              </div>
              <el-progress :percentage="progress"></el-progress>
            </div>
          </t-form-item>
        </t-form>
      </template>
    </t-dialog>
    <t-dialog :visible="choice_poin_toast" header="查找地点坐标" height="600px" placement="center"
              width="60%" @close="choice_poin_toast=false" @confirm="choice_poin">
      <template #body>
        <div style="width: 300px;margin-bottom: 20px;display: flex">
          <t-select v-model="address" :loading="search_address_loading" :onEnter="addres_cg" :options="address_option"
                    creatable filterable placeholder="输入详细地址" @change="set_address"/>
          <!--          <t-input placeholder="请输入内容" v-model="address" />-->
          <!--          <t-button :loading="search_address_loading" theme="primary" style="margin-left: 10px;" @click="search_address">搜索地点</t-button>-->
        </div>
        <div id="container"></div>
      </template>
    </t-dialog>
  </div>
</template>
<script>
import {add_venue, del_venue, edit_venue, get_address_poin, get_venue_id, get_venue_list} from "../../api/venue";
import {shallowRef} from '@vue/reactivity'
import {Icon} from 'tdesign-icons-vue-next'
import AMapLoader from '@amap/amap-jsapi-loader';
import {MessagePlugin} from "tdesign-vue-next";
import {ElDialog, ElProgress, ElUpload} from 'element-plus'
import 'element-plus/dist/index.css'
import {get_progress, up} from '../../utils/tx_cos_up'

export default {
  components: {
    Icon,
    ElUpload, ElProgress,
    ElDialog
  },
  setup() {
    const map = shallowRef(null);
    return {
      map,
    }
  },
  data() {
    return {
      search_address_loading: false,
      current: 1,
      data: [],
      pages: 1,
      limit: 20,
      total: 0,
      table_loading: false,
      columns: [
        {
          colKey: 'name',
          title: '展馆名',
          className: 'row_tr',
          ellipsis: true,
        },
        {
          colKey: 'address_str',
          title: '展馆地点',
          className: 'row_tr',
        },
        {
          colKey: 'con',
          title: '展馆介绍',
          ellipsis: true,
          className: 'row_tr',
        },
        {
          colKey: 'work_time',
          title: '营业时间',
          className: 'row_tr',
        },
        {
          colKey: 'opertion',
          title: '操作',
          align: 'center',
          width: '250px',
          className: 'row_tr',
        }
      ],
      add_toast: false,
      formData: {
        name: '',
        type: '',
        work_time: {
          start: '',
          end: ""
        },
        address_str: "",
        address_poin: {
          lat: '',
          lng: ""
        },
        con: "",
        img: '',
        time_tag: ''
      },
      choice_time: [],
      choice_poin_toast: false,
      address: '',
      markers: [],
      file1: [],
      rules: {
        name: [{required: true}, {min: 4}, {max: 50}],
        type: [{required: true}],
        work_time: [{required: true}],
        address_str: [{required: true}],
        address_poin: [{required: true}],
        con: [{required: true}],
        img: [{required: true}]
      },
      type_list: [
        {
          label: '红色教育',
          value: '红色教育'
        },
        {
          label: '法制教育',
          value: '法制教育'
        },
        {
          label: '安全教育',
          value: '安全教育'
        },
        {
          label: '民族团结',
          value: '民族团结'
        },
        {
          label: '科技创新',
          value: '科技创新'
        },
        {
          label: '文化艺术',
          value: '文化艺术'
        },
        {
          label: '职业体验',
          value: '职业体验'
        },
        {
          label: '其他',
          value: '其他'
        }
      ],
      venue_toast_title: '添加展馆',
      address_option: [],
      progress: 0,
      progress_time: null,
      prev_toast: false,
      prev_item: []
    }
  },
  computed: {
    time() {
      return (work_time) => {
        let start = JSON.parse(work_time).start
        let end = JSON.parse(work_time).end
        return `${start}至${end}`
      }
    },
    show_poin() {
      if (this.formData.address_poin.lat !== '') {
        return `${this.formData.address_poin.lat},${this.formData.address_poin.lng}`
      } else {
        return ''
      }
    }
  },
  mounted() {
    this.get_list()
    // console.log(up())
    // this.initMap()
  },
  methods: {
    prev_img_video(e) {
      this.prev_toast = true
      this.prev_item = e.url
    },
    //上传图片
    async up_img(e) {
      let x = 0
      setInterval(() => {
        this.progress = Number(get_progress())
        if (this.progress === 100 || x === 1) {
          this.progress_time = null
          clearInterval(this.progress_time)
        }
      })
      let res = await up((e.file.uid.toString() + e.file.name), e.file)
      x = 1
      if (this.formData.img === '') {
        this.formData.img += 'https://' + res
      } else {
        this.formData.img += '|' + 'https://' + res
      }
      console.log(this.formData.img)
    },
    //初始化地图
    initMap() {
      this.choice_poin_toast = true
      this.address = ''
      AMapLoader.load({
        key: "1c9d6f1f79687710334ee12f44b89411",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 5,           //初始化地图级别
          center: [105.602725, 37.076636], //初始化地图中心点位置
        });
        this.map.on('click', (e) => this.user_set_poin(e))
      }).catch(e => {
        console.log(e);
      })
    },
    async get_list() {
      this.table_loading = true
      let res = await get_venue_list({pages: this.pages, limit: this.limit})
      this.data = [...res.data, ...this.data]
      this.table_loading = false
      console.log(this.data)
      this.total = res.count
    },
    pageChange(e) {
      this.pages = e
      this.data = []
      this.get_list()
    },
    pageSizeChange(e) {
      this.limit = e
      this.data = []
      this.get_list()
    },
    timeChange(e) {
      this.formData.work_time.start = e[0]
      this.formData.work_time.end = e[1]
    },
    //设置坐标
    choice_poin() {
      this.choice_poin_toast = false
      console.log('sdafasdf')
    },
    addres_cg(e) {
      this.address = e.inputValue
      this.search_address()
    },
    async search_address() {
      if (this.address === '') {
        MessagePlugin.warning({content: '请输入详细地址'})
        return
      }
      this.search_address_loading = true
      let data = await get_address_poin({address: this.address})
      this.search_address_loading = false
      this.address_option = []
      data.data.pois.forEach((item, index) => {
        this.address_option.push({
          label: item.name,
          location: item.location,
          value: index.toString()
        })
      })
      console.log(data)
    },
    set_address(e) {
      console.log(this.address_option[e])
      this.set_poin(this.address_option[e].location.split(','))
    },
    set_poin(poin) {
      this.removeMarkers()
      this.formData.address_poin.lat = poin[0]
      this.formData.address_poin.lng = poin[1]
      var marker = new AMap.Marker({
        position: new AMap.LngLat(poin[0], poin[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter(poin);
      this.map.setZoom(16);
    },
    //用户手动设置定位点
    user_set_poin(e) {
      this.removeMarkers()
      this.formData.address_poin.lat = e.lnglat.lng
      this.formData.address_poin.lng = e.lnglat.lat
      var marker = new AMap.Marker({
        position: new AMap.LngLat(e.lnglat.lng, e.lnglat.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: this.address
      });
      this.markers.push(marker)
      this.map.add(marker);
      this.map.setCenter([e.lnglat.lng, e.lnglat.lat]);
    },
    removeMarkers() {
      for (let i of this.markers) {
        this.map.remove(i)
      }
      this.markers = []
    },
    upImg(e) {
      console.log(e)
      if (this.formData.img === '') {
        this.formData.img += e.data
      } else {
        this.formData.img += '|' + e.data
      }
      console.log(this.formData.img)
    },
    del_img(e) {
      console.log(e)

      let img = ''
      this.formData.img.split('|').forEach((item, index) => {
        if (item !== e.url) {
          if (img === '') {
            img += item
          } else {
            img += '|' + item
          }
        }
      })
      this.formData.img = img
      console.log(this.file1)
      console.log(this.formData.img)
    },
    initForm() {
      this.formData = {
        name: '',
        type: '',
        work_time: {
          start: '',
          end: ""
        },
        address_str: "",
        address_poin: {
          lat: '',
          lng: ""
        },
        con: "",
        img: ''
      }
      this.choice_time = []
      this.file1 = []
      this.$refs.form.clearValidate()
    },
    address_set(e) {
      console.log(e)
    },
    async up() {
      if (await this.$refs.form.validate() !== true) {
        MessagePlugin.warning({content: '请填写完整'})
        return
      }
      if (this.formData.address_poin.lat === '' || this.formData.work_time.start === '') {
        MessagePlugin.warning({content: '请填写完整'})
        return
      }
      if (this.venue_toast_title === '编辑展馆') {
        this.edit_up()
        return
      }
      console.log(this.formData)
      this.$loading(true);
      let data = await add_venue(this.formData)
      this.$loading(false)
      if (data.code === 100) {
        MessagePlugin.success({content: "添加成功！"})
        this.add_toast = false
      } else {
        MessagePlugin.error({content: "添加失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    async edit_up() {
      this.$loading(true);
      let id = this.formData.id
      delete this.formData.id
      let data = await edit_venue({id, up: this.formData})
      this.$loading(false)
      if (data.code === 100) {
        MessagePlugin.success({content: "编辑成功！"})
        this.add_toast = false
      } else {
        MessagePlugin.error({content: "编辑失败！"})
      }
      this.data = []
      this.get_list()
      console.log(data)
    },
    //  删除展馆
    async del_venue({id}) {
      let {code, msg} = await del_venue({id})
      if (code === 100) MessagePlugin.success(msg)
      else MessagePlugin.error(msg)
      this.data = []
      this.get_list()
    },
    //  编辑展馆
    async edit({id}) {
      this.$loading(true)
      let {data, code, msg} = await get_venue_id({id})
      this.$loading(false)
      this.venue_toast_title = '编辑展馆'
      console.log(data)
      this.formData = data
      this.formData.address_poin = JSON.parse(data.address_poin)
      this.formData.work_time = JSON.parse(data.work_time)
      this.file1 = []
      this.formData.img.split('|').map(item => {
        this.file1.push({
          lastModified: 123045,
          name: 'sdaf',
          percent: 100,
          raw: {},
          response: {},
          size: 10,
          status: 'success',
          type: 'image',
          url: item
        })
      })

      this.choice_time = [this.formData.work_time.start, this.formData.work_time.end]
      this.add_toast = true
    }
  }
}
</script>

<style scoped>
.prev {
  width: 100%;
  height: 100%;
}

.prev > img,
.prev > video {
  width: 100%;
  height: 100%;
}

#container {
  width: 100%;
  height: 600px;
}

.table {
  height: calc(100% - 90px);
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}

.index {
  width: 100%;
  height: 100%;
  background-color: var(--td-bg-color-container);
}

.row_tr {
  height: 40px;
}

.header > h2 {
  font-size: 24px;
  color: #383838;
  letter-spacing: 1px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 8px 12px;
  box-sizing: border-box;
}

.pages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 8px;
  box-sizing: border-box;
}

.tdesign-demo__select-input-ul-single {
  padding: 4px 0;
}

.tdesign-demo__select-input-ul-single > li {
  display: block;
  border-radius: 3px;
  height: 40px;
  line-height: 22px;
  cursor: pointer;
  padding: 9px 8px;
  color: var(--td-text-color-primary);
  transition: background-color 0.2s cubic-bezier(0.38, 0, 0.24, 1);
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tdesign-demo__select-input-ul-single > li:hover {
  background-color: var(--td-bg-color-container-hover);
}
</style>

<style>
.t-table th:not([align]), .t-table td:not([align]) {
  height: 40px;
}

.index .t-table__body > tr {
  height: 40px;
}

.index .t-time-picker {
  width: 100%;
}

.index .t-pagination__total {
  text-align: left;
}

.index .t-button--variant-base.t-button--theme-primary {
  background-color: #42b983;
  border-color: #42b983;
}

.index .t-button--variant-base.t-button--theme-primary > div > div {
  background-color: #24b07d !important;
}

.index > .t-table__header > tr {
  background-color: #eee;
}
</style>
